import React from "react"

const Jumbotron = ({ pageInfo }) => {
	let image = 'none';
	if (pageInfo.backgroundImage) {
		image = `url(${pageInfo.backgroundImage})`;
	}
	
	let className = 'jumbotron m-0';
	if (pageInfo.className) {
		className = className + ' ' + pageInfo.className;
	}

	return (
		<div className={className} style={{ backgroundImage: image }}>
			<div className={'container'}>
				<div className="row">
					<div className="col text-center p-4">
						<h1 className="jumbotron__title">{ pageInfo.title }</h1>
						<p className='jumbotron__subtitle'>{ pageInfo.subtitle }</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Jumbotron
