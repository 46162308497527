import React from "react"

class NavButton extends React.Component {
	handleClick = () => {
		document.getElementById("sideNav").style.width = "250px";
		// document.getElementById("main").style.marginRight = "250px";
		document.getElementById("navButton").classList.add('hidden');
	}
	render() {
		return (
			<button id="navButton" className="ml-auto" onClick={this.handleClick}>
				<i className="fal fa-2x fa-bars" />
			</button>
		)
	}
}

const CustomNavbar = ({ pageInfo }) => {
  return (
	  <div id="navbar-fixed" className="position-fixed fixed-top">
		  <div className="container">
			  <div className="row">
				  <div className="col">
					  <nav className="navbar navbar-expand-md navbar-light">
						  <a className="navbar-brand" href="/">
							  <img src="/images/logo.svg" className="d-inline-block align-top" alt="" id="navbar-logo" />
						  </a>
						  {/*
						  	<NavButton />
						  */}
					  </nav>
				  </div>
			  </div>
		  </div>
	  </div>
  )
}

export default CustomNavbar
