import React from "react"

import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import ArticleList from "./article-list"
import NewsList from "./news-list"

const Footer = ({ pageInfo }) => {
  return (
	<footer>
		<Container>
			<Row>
				<Col xs={{span:12,order:'first'}}>
					<div itemScope itemType="http://schema.org/WebPage" className="text-center my-4">
						<img itemProp="logo" className="img-fluid footer-logo" src="/images/logo.svg"/>
						<span itemProp="name" className="sr-only">Blendics Incorporated</span>
						<span itemProp="description" className="sr-only">BLENDED INTEGRATED CIRCUIT SYSTEMS</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="text-center my-4">
						<div itemScope itemType="http://schema.org/WebPage" className="footer-copyright mt-4">
							<small>
								&copy; <span itemProp="copyrightYear">{new Date().getFullYear()}</span> - <span itemProp="copyrightHolder">Blendics Incorporated.</span> | All Rights Reserved
							</small>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	</footer>
  )
}

export default Footer
