/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

import Navbar from "./navBar"
import Footer from "./footer"
import FiftyFifty from "./fifty-fifty"


const Layout = ({ children, pageInfo }) => {
	return (
		<>
			<Navbar pageInfo={pageInfo}/>
			<main>{children}</main>
			{/*
				<FiftyFifty pageInfo={{backgroundImage: '', backgroundColor: '#eeeeee', color: '#ffffff' }} >
					<div className="text-center">
						<span className="p-2 text-dark">Schedule a demo</span>
						<a target="_blank" className="m-2 btn btn-outline-dark" href="mailto:info@blendics.com?subject=Demo Request"><i className="fa fa-envelope"></i> info@blendics.com</a>
					</div>
				</FiftyFifty>
			*/}
			<Footer />
		</>
	)
}

export default Layout