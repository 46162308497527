import React from "react"

import { Container, Row, Col } from "react-bootstrap"

const FiftyFifty = ({ children, pageInfo }) => {

	let backgroundColor = '';
	let backgroundImage = '';
	let image;

	if (pageInfo.backgroundColor) {
		backgroundColor = pageInfo.backgroundColor;
	}

	if (pageInfo.backgroundImage) {
		backgroundImage = pageInfo.backgroundImage;
	}

	if (pageInfo.imgUrl) {
		image = <img
			src={ pageInfo.imgUrl }
			alt=""
		/>;
	} else {
		image = '';
	}


	return (
		<section className={'fifty-fifty'} style={{ backgroundImage: 'url("'+backgroundImage+'")', backgroundColor: backgroundColor }}>
            {children}
		</section>
	)
}

export default FiftyFifty
